import * as i0 from '@angular/core';
import { InjectionToken, Injectable, NgModule } from '@angular/core';
import { TENANT_ID_PARAM } from '@soleran/contracts';
import { map } from 'rxjs';
import * as i2 from '@angular/common/http';
const TemplateModuleConfig = new InjectionToken('Template Module Configuration Token');
class TemplateService {
  constructor(_config, _httpClient) {
    this._config = _config;
    this._httpClient = _httpClient;
    this.apiBaseUrl = this._config.apiBaseUrl;
    this.apiPath = `${this.apiBaseUrl}/api/template`;
  }
  get(...args) {
    const [options] = args;
    if (!options) return this._getAll();
    if (!!options.id) {
      return this._get(options);
    } else {
      return this._getAll(options);
    }
  }
  _get(options) {
    const url = new URL(this.apiPath);
    if (!!options.id) url.searchParams.append('id', options.id);
    if (!!options.tenantId) url.searchParams.append(TENANT_ID_PARAM, options.tenantId);
    return this._httpClient.get(url.href);
  }
  _getAll(options) {
    const url = new URL(this.apiPath);
    if (!!options?.ids) url.searchParams.append('id', options.ids.join(','));
    if (!!options?.tenantId) url.searchParams.append(TENANT_ID_PARAM, options.tenantId);
    return this._httpClient.get(url.href).pipe(map(templates => {
      if (!templates) return [];
      return !Array.isArray(templates) ? [templates] : templates;
    }));
  }
  create(createContract, options) {
    const url = new URL(this.apiPath);
    if (!!options?.tenantId) url.searchParams.append(TENANT_ID_PARAM, `${options.tenantId}`);
    return this._httpClient.post(url.href, createContract);
  }
  update(updateContract, options) {
    const url = new URL(this.apiPath);
    if (!!options?.tenantId) url.searchParams.append(TENANT_ID_PARAM, `${options.tenantId}`);
    return this._httpClient.put(url.href, updateContract);
  }
  static {
    this.ɵfac = function TemplateService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TemplateService)(i0.ɵɵinject(TemplateModuleConfig), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TemplateService,
      factory: TemplateService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TemplateService, [{
    type: Injectable
  }], () => [{
    type: TemplateModuleConfig
  }, {
    type: i2.HttpClient
  }], null);
})();
class TemplateModule {
  static forRoot(config) {
    return {
      ngModule: TemplateModule,
      providers: [{
        provide: TemplateModuleConfig,
        useValue: config
      }, TemplateService]
    };
  }
  static {
    this.ɵfac = function TemplateModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TemplateModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TemplateModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TemplateModule, [{
    type: NgModule
  }], null, null);
})();

/*
 * Public API Surface of template
 */

/**
 * Generated bundle index. Do not edit.
 */

export { TemplateModule, TemplateModuleConfig, TemplateService };
